
// Misc Vars
$max-width:1400px;
$max-width-narrow:800px;
$font-size-base:15px;
$font-weight-base:300;
$line-height-base:1.6rem;
$header-height: 50px;
$input-height:50px;
$button-height:50px;
$vmargin:10rem;

// Fonts
$font-sans-serif: 'Helvetica Neue', sans-serif;

// Google Material Grey's
$color-grey-50: #FAFAFA;
$color-grey-100: #F5F5F5;
$color-grey-200: #EEEEEE;
$color-grey-300: #E0E0E0;
$color-grey-400: #BDBDBD;
$color-grey-500: #9E9E9E;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;
$color-grey-900: #212121;

// Brand Colors
$color-body: $color-grey-800;

// Breakpoints
$ptab: new-breakpoint(601px);
$tab: new-breakpoint(769px);
$desk: new-breakpoint(1025px);
$wideDesk: new-breakpoint(1201px);
$wideMax: new-breakpoint(1401px);
$mobileOnly: new-breakpoint(max-width 600px);
$ptabAndLess: new-breakpoint(max-width 768px);
$laptopHeightOnly: new-breakpoint(max-height 700px);

// Font
$sans-serif: 'Montserrat', sans-serif;
$serif: 'EB Garamond', serif;