.btn{
	text-decoration: none;
	outline:0;
	display: inline-block;
	color:$color-body;
	height:$button-height;
	padding:0 2rem;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size:0.8rem;
	line-height:$button-height;
	user-select: none;
	outline:0;
	cursor:pointer;

	&.solid{
    background:$color-body;
    color:white;
		border:none;
		line-height:calc(#{$button-height} + 3px);

		&.white{
			background:white;
	    color:$color-body;
		}
	}

	&.outline{
		border:solid 2px $color-body;
		color:$color-body;
		background:transparent;

		&.white{
			border:solid 2px white;
	    color:white;
		}
	}

}
