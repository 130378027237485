
*, *:before, *:after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
}
body {
	font-family:$font-sans-serif;
	font-weight:$font-weight-base;
	font-size:$font-size-base;
	line-height:$line-height-base;
	color:$color-body;
}
a{
	color:$color-body;
}
p{
	margin:0 0 1.25rem 0;

	&.display{
		font-size:1rem;
		line-height:1.75em;

		@include media($ptab) {
			font-size:1.25rem;
		}
	}
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
ol{
	display: inline-block;
	margin:0;
	padding:0 0 0 1rem;
}
button{
	border:none;
	padding:0;
	margin:0;
	display:inline-block;
	cursor:pointer;
	background:none;
	outline:none;
}
figure{
	margin:0;
	padding:0;
}
main{
	display: block;
	position: relative;
}
blockquote{
	margin:1.25em 0;
	font-weight:100;
	font-size:1rem;
	line-height:1.5em;

	@include media($ptab) {
		font-size:1.25rem;
		line-height:1.75em;
	}
}




// Global Styles
// -----------------------------------
.bg-image{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:0;
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
	transition:all .5s ease;
	transform: translate3d(0,0,0);
}

.reveal-hidden{
	opacity:0;
	transform:translate3d(0,50px,0);
}

.reveal-show{
	transition:
		transform 1s,
		opacity 1.5s
	;
	opacity:1;
	transform:translate3d(0,0,0);
}

.alignleft {
  @include media($tab) {
		float: left;
	}
}

.alignright {
	@include media($tab) {
		float: right;
	}
}

.smaller{
	font-size:0.6rem;
	line-height:1.5em;
	margin-top:0.8rem;

	@include media($ptab) {
		font-size:0.8rem;
	}
}

.inner-wrap{
	width:90%;
	max-width:$max-width;
	margin:0 auto;
	position: relative;
}

.narrow-wrap{
	width:90%;
	margin:0 auto;
	position: relative;
	max-width:$max-width-narrow;
}
