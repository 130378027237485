.page-home {
// Art Department
  .icons {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    li {
      margin-right: 40px;
    }
  }

  // Contact
  .contact a {
    text-decoration: none;
  }
}