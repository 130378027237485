
// Flexbox column system
.columns{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .column{
    flex:0 0 100%;

    &[rel="2"]{
      flex:0 0 100%;

      @include media($ptab) {
        flex:0 0 47%;
      }
    }
    &[rel="3"]{
      flex:0 0 28%;
      &:nth-child(3n+2){ margin:0 8%; }
    }
    &[rel="4"]{
      flex:0 0 24%;
    }

    &[rel="1/3"]{
      flex:0 0 100%;

      @include media($ptab){
        flex:0 0 32%;
      }
    }
    &[rel="2/3"]{
      flex:0 0 100%;

      @include media($ptab){
        flex:0 0 63%;
      }
    }
  }
}
