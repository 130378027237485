
// Import all scss files
@import "bourbon/bourbon";
@import "neat/neat";
@import "mixins/all";
@import "common/all";
@import "partials/all";
@import "pages/all";

body {
  color: #555;
}