.header {

  &__container {
    height: 120px;
    display: flex;
    align-items: center;
  }

  &__title {
    color: #000;
    font-family: $sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin: 0;
    line-height: 2rem;

    a {
      color: #000;
      text-decoration: none;
    }
  }

  &__subtitle {
    color: #000;
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
  }
}