.accordion {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;

  &:nth-child(odd) {
    border-top: none;
  }

  &:nth-child(even) {
    border-top: none;
  }

  &:first-child {
    border-top: 1px solid #e5e5e5;
  }

  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }

  opacity: 0;
  &.is-show {
    opacity: 1;
    transition: opacity .2s ease;
  }
  
  &__header {
    height: 75px;
    display: flex;
    align-items: center;
    cursor: pointer;

    h2 {
      font-family: $serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }
  }

  &__body {
    font-family: $serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease;
  }

  &.is-active {
    .accordion__body {
      max-height: 1000px;
      transition: max-height .5s ease;
    }
  }
}