
h1, h2, h3, h4, h5, h6 {
	margin:0 0 0.5rem 0;
  line-height:1.5em;
	font-weight:100;
}

h1 {
	font-size:2.5rem;
}

h2 {
	font-size:2rem;
}

h3 {
	font-size:1.4rem;
	font-weight:200;
}

h4{
	font-size:1.2rem;
	font-weight:200;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src:
		url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'), /* Pretty Modern Browsers */
		url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src:
		url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
		url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: normal;
  src:
		url('../fonts/EB_Garamond/EB_Garamond-Regular.woff') format('woff'), /* Pretty Modern Browsers */
		url('../fonts/EB_Garamond/EB_Garamond-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: normal;
  src:
		url('../fonts/EB_Garamond/EB_Garamond-Italic.woff') format('woff'), /* Pretty Modern Browsers */
		url('../fonts/EB_Garamond/EB_Garamond-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
}